import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MUISelect
} from "@material-ui/core";

import "./Select.scss";

export class Select extends Component {
  constructor(props) {
    super(props);
    if (this.props.value === "" && this.props.options[0].value !== "") {
      this.state = {
        value: this.props.options[0].value
      };
    } else {
      this.state = {
        value: this.props.value || ""
      };
    }

    this.handleChange = this.handleChange.bind(this);
  }

  // handleChange(event, index, value) {
  //   // we store the index but pass the value
  //   this.setState({ selected: index, value });
  //   if (typeof this.props.onChange !== "undefined") {
  //     // @TODO Remove dependency with dataProperty, 'name' is more appropriate.
  //     const name = this.props.dataProperty || this.props.name;
  //     this.props.onChange(name, value);
  //   }
  // }

  handleChange = event => {
    const { value } = event.target;
    this.setState({ value });
    if (typeof this.props.onChange !== "undefined") {
      // @TODO Remove dependency with dataProperty, 'name' is more appropriate.
      const name = this.props.dataProperty || this.props.name;
      this.props.onChange(name, value);
    }
  };

  render() {
    const { isRequired = false, label, options } = this.props;

    return (
      <FormControl
        required={isRequired}
        className={`cms-select cms-input-text ${this.props.dataProperty}`}
        key={this.props.id}
      >
        {label && (
          <InputLabel
            className="input-label"
            htmlFor={this.props.name}
            style={this.props.labelStyle}
          >
            {label}
          </InputLabel>
        )}

        <MUISelect
          value={this.state.value}
          onChange={this.handleChange}
          inputProps={{
            name: this.props.name,
            id: this.props.name
          }}
          multiple={this.props.multiple}
          displayEmpty
        >
          {options &&
            options.map((option, index) => {
              const { label: optionLabel, value } = option;
              const localizedOptionLabel = optionLabel.en || optionLabel;
              return (
                <MenuItem
                  key={`${localizedOptionLabel.replace(" ", "-")}`}
                  value={value}
                >
                  {localizedOptionLabel}
                </MenuItem>
              );
            })}
        </MUISelect>
        <FormHelperText>{this.props.hintText}</FormHelperText>
      </FormControl>
    );
  }
}

// Select.defaultProps = {
//   selected: 0,
//   needRefresh: false,
//   hintStyle: {
//     bottom: 3,
//     left: 20
//   },
//   style: { width: 150 },
//   underlineStyle: { color: "#abcdeff" }
// };
