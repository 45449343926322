import React, { useEffect, useState } from "react";

import _map from "lodash/map";
import _findIndex from "lodash/findIndex";

import ListItem from "./ListItem";
import "./List.scss";

/**
 * @description Creates a list of components: inputs, buttons, navigation
 * items, etc.
 *
 * Is this true: The default type assumes an input, and its default is a text input.
 */

// Retain for conversion to TypeScript:
// List.propTypes = {
//   handleInputChange: PropTypes.func,
//   icon: PropTypes.string,
//   items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//   muiTheme: PropTypes.object, // Object inheriting Material UI Context styles
//   styleName: PropTypes.string,
//   slugBase: PropTypes.string,
//   injectLanguage: PropTypes.bool,
//   language: PropTypes.string,
//   handleEditClick: PropTypes.func,
//   handleAddClick: PropTypes.func,
//   handleDeleteClick: PropTypes.func,
//   handleFrameSetterFunc: PropTypes.func,
//   data: PropTypes.object
// };

export const List = ({
  id,
  styleName = "standard",
  slugBase,
  items,
  data,
  icon,
  injectLanguage,
  language,
  handleInputChange,
  handleEditClick,
  handleAddClick,
  handleDeleteClick,
  handleFrameSetterFunc
}) => {
  const [itemsLocal, setItemsLocal] = useState();
  const [dataLocal, setDataLocal] = useState();

  useEffect(() => {
    // TODO: Determine if items is ever an array.
    if (typeof items === "object") {
      setItemsLocal({ ...items });
    } else {
      setItemsLocal([...items]);
    }

    setDataLocal({ ...data });
  }, [items, data]);

  let itemsToBeOrdered = [];
  let itemsNotOrdered = [];
  let orderedTotal = [];
  _map(itemsLocal, item => {
    if (typeof item.order !== "undefined") {
      itemsToBeOrdered.push(item);
    } else {
      itemsNotOrdered.push(item);
    }
  });
  itemsToBeOrdered.sort((a, b) => a.order - b.order);
  orderedTotal = orderedTotal.concat(itemsToBeOrdered, itemsNotOrdered);

  return (
    <div className="cms-list col-sm-12" id={id}>
      {_map(orderedTotal, item => {
        const key = item.param || item.label;
        // If the item in the list is an asset for an assetEditor, we pass all the data inside and do not look for the key
        // @TODO: Find a better way of handling the above
        const value = dataLocal
          ? item.type === "assetEditor"
            ? dataLocal[key] || dataLocal
            : typeof dataLocal[key] !== "undefined"
            ? dataLocal[key]
            : ""
          : null;
        return (
          <ListItem
            value={value}
            handleInputChange={handleInputChange}
            handleEditClick={handleEditClick}
            handleAddClick={handleAddClick}
            handleDeleteClick={handleDeleteClick}
            handleFrameSetterFunc={handleFrameSetterFunc}
            icon={icon}
            injectLanguage={injectLanguage}
            language={language}
            styleName={styleName}
            slugBase={slugBase}
            key={item.param || item.label}
            item={item}
          />
        );
      })}
    </div>
  );
};
