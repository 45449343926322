import React, { useState } from "react";

import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import { Done, MoreHoriz } from "@material-ui/icons";

import Logger from "utils/logger";

/**
 * PopupMenu
 * @description this component extends the MUI-popup
 */

export const PopupMenu = ({
  options,
  value,
  onChange,
  useIconButton,
  mode = "dark",
  buttonColorLight = "rgba(0, 0, 0, 0.54)",
  buttonColorDark = "#d7d7d7",
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonBack, setButtonBack] = useState("transparent");

  const handleMenuOpenClick = event => {
    // Stop propagation and prevent default so that the popup
    // menu works on the slideshow card.
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(!isOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  const handleMenuItemClick = newValue => () => {
    setIsOpen(false);
    setInternalValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  // TODO: Handled this with the theme.
  const handleButtonHover = () => {
    setButtonBack("rgba(255, 255, 255, 0.10)");
  };

  const handleButtonOut = () => {
    setButtonBack("transparent");
  };

  let buttonStyle = {
    color: buttonColorLight,
    textTransform: "capitalize",
    letterSpacing: "0.02em",
    minWidth: 50,
    background: buttonBack
  };
  if (mode === "dark") {
    buttonStyle.color = buttonColorDark;
  }

  // TBD: Will we need custom mouse over background handling for the icon button?
  // const iconButtonStyle = {
  //   width: 40,
  //   height: 40,
  //   padding: 0
  // };

  const iconStyle = {
    width: 24,
    height: 24,
    color: "white"
  };

  return (
    <div
      className={`popup-menu ${className ? className : ""}`}
      style={{ float: "left", marginRight: 4 }}
    >
      {useIconButton ? (
        <IconButton
          className="icon more"
          // style={iconButtonStyle}
          onClick={handleMenuOpenClick}
        >
          <MoreHoriz style={iconStyle} />
        </IconButton>
      ) : (
        <Tooltip title="Change sort by">
          <Button
            style={buttonStyle}
            onMouseEnter={handleButtonHover}
            onMouseLeave={handleButtonOut}
            onClick={handleMenuOpenClick}
          >
            {options.find(option => option.value === internalValue).label}
          </Button>
        </Tooltip>
      )}

      {options && (
        <Menu open={isOpen} anchorEl={anchorEl} onClose={handleMenuClose}>
          {options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={handleMenuItemClick(option.value)}
            >
              {option.value === internalValue && (
                <ListItemIcon>
                  <Done />
                </ListItemIcon>
              )}
              {/* 
              TODO: Figure out how to deal with the fact that React complains about this component: 
              
              The <ListItemText /> component appears to be a function component that returns
              a class instance. Change ListItemText to a class that extends React.Component
              instead. If you can't use a class try assigning the prototype on the function
              as a workaround. `ListItemText.prototype = React.Component.prototype`. Don't
              use an arrow function since it cannot be called with `new` by React.
              */}
              <ListItemText inset primary={option.label} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};
