import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";

import { Button } from "@material-ui/core";

import { Dialog, TableGrid } from "components";
import ContentContainer from "modules/ContentContainer";
import * as storyCategoryActions from "actions/story-category";
import { localizeTextProps2 } from "helpers/localizationHelper";
import * as selectors from "sagas/selectors";
import "./styles.scss"; // need to leverage the same selector actions from story

const CategoryListBrowser = ({
  categories,
  sidebarMode,
  pageTitle,
  createCategory,
  gotoCategoryEditor,
  navigateToNew,
  handleComponentMounted,
  deleteCategories
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    handleComponentMounted();
  }, []);

  // TODO: Simplify!
  // This is a very convoluted way of updating the URL after
  // a category is created to display the new category in the
  // category editor.
  useEffect(() => {
    if (navigateToNew) {
      gotoCategoryEditor(navigateToNew);
    }
  }, [navigateToNew]);

  const openDeleteModal = e => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const proceedToDelete = () => {
    setDeleteModalOpen(false);
    const categoryIDs = selected.map((accumulator, gridItem) => {
      accumulator.push(gridItem.id);
    }, []);
    deleteCategories(categoryIDs);
  };

  const handleRowSelection = (rows, items) => {
    setSelected(items);
  };

  const columns = [
    {
      id: 1,
      label: "Name"
    },
    {
      id: 2,
      label: "Color"
    },
    {
      id: 3,
      label: ""
    }
  ];

  const topBar = (
    <div className="filters-bar-container">
      {selected.length > 0 ? (
        <Button
          variant="contained"
          id="delete-button"
          key="delete-button"
          color="primary"
          className="new-asset-button-v2"
          style={{ textTransform: "none" }}
          onClick={openDeleteModal}
        >
          DELETE
        </Button>
      ) : (
        <Button
          variant="contained"
          id="new-button"
          key="new-button"
          color="primary"
          className="new-asset-button-v2"
          style={{ textTransform: "none" }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            createCategory();
          }}
        >
          NEW
        </Button>
      )}
    </div>
  );

  const sortedCategories = _sortBy(categories, ["name"]);
  const tableGridItems = {};
  _map(sortedCategories, category => {
    tableGridItems[category._id] = {
      // selectable: category._id !== currentCategoryID ? true : false,
      selectable: true,
      id: category._id,
      columns: {
        1: { id: 1, label: category.name },
        // 2: { id: 2, label: `hex:${category.color}` }
        2: {
          id: 2,
          label: `rgba(${category.color.r}, ${category.color.g}, ${category.color.b}, ${category.color.a})`
        }
      }
    };
  });

  return (
    <div className="scene-list-browser">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <ContentContainer
        backgroundColor="white"
        isLoading={false}
        className="content-container"
        sidebarMode={sidebarMode}
        topBar={topBar}
      >
        {tableGridItems ? (
          <TableGrid
            items={tableGridItems}
            columns={columns}
            handleRowSelectionFunc={handleRowSelection}
            idNode="id"
            editAction={gotoCategoryEditor}
          />
        ) : null}
      </ContentContainer>

      <Dialog
        content="Once deleted, these categories cannot be restored"
        onConfirm={proceedToDelete}
        confirmLabel="Delete"
        onCancel={closeDeleteModal}
        open={deleteModalOpen}
        title="Delete Selected Categories?"
      />
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const { params } = ownProps.match;
  const { storyID } = params;

  // Need story and story type param (human-readable) to define page title.
  // The story object has the type ID, and storyTypes are keyed off human-readable
  // story type name.
  const story = state.getIn(["data", "stories", storyID]);
  const storyTypeParam = story
    ? selectors.getStoryTypeParam(state, story.get("type"))
    : null;
  const storyType = storyTypeParam
    ? state.getIn(["base", "storyTypes", storyTypeParam])
    : null;
  const pageTitle = storyType ? `${storyType.get("title")} Categories` : null;

  const langID = state.getIn(["base", "config", "langID"]);
  const forms = state
    .getIn(["ui", "storyEditor", "forms", "category"], Map())
    .toJS();
  const storyCategories = state
    .getIn(["data", "storyCategories"], Map())
    .toJS();
  delete storyCategories.revertCopy;
  const categories = _map(storyCategories, storyCategory =>
    localizeTextProps2(langID, forms.col1, storyCategory)
  );
  const sidebarMode = `${state.getIn(["base", "mainSidebar", "mode"])}Extra`;
  const navigateToNew = state.getIn([
    "ui",
    "storyCategoriesBrowser",
    "navigateToNew"
  ]);

  // const deleteErrorMessage = state.getIn([
  //   "ui",
  //   "storyCategoryManager",
  //   "delete_error"
  // ]);
  // const deleteError = _isEmpty(deleteErrorMessage) ? false : deleteErrorMessage;
  // const currentCategoryID = state.getIn(["data", "story-category", "_id"]);

  return {
    storyID,
    pageTitle,
    sidebarMode,
    categories,
    navigateToNew
    // currentCategoryID,
    // deleteError
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { storyID } = stateProps;
  return Object.assign({}, stateProps, {
    handleComponentMounted: () => {
      dispatchProps.handleCategoryBrowserMounted(storyID);
    },
    gotoCategoryEditor: categoryID => {
      ownProps.history.push(`/stories/${storyID}/categories/${categoryID}`);
    },
    createCategory: () => {
      dispatchProps.createCategory(storyID);
    },
    deleteCategories: categoryIDs => {
      dispatchProps.deleteCategories(categoryIDs);
    }
  });
}

export default connect(
  mapStateToProps,
  storyCategoryActions,
  mergeProps
)(CategoryListBrowser);
