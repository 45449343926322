import React, { useState } from "react";

import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _map from "lodash/map";
import _forIn from "lodash/forIn";

import { MenuItem, Select } from "@material-ui/core";

import { Dialog, List } from "components";
import Sidebar from "modules/Sidebar/Sidebar";
import AssetSelector from "modules/Library/AssetSelector";
import ThemeSelector from "modules/ThemeSelector";
import "./Sidebar.scss";
import styles from "./Sidebar.scss";
// import { checkAndProcessCheckboxes, handleForeignDependencies } from "support";

export const StoryEditorSidebar = ({
  languages,
  langID,
  sidebarMode,
  sidebarData,
  section = null,
  navigation: formElements,
  slugBase,
  title,
  className,
  goBackAction,
  showButtons,
  hideRevertButton,
  revertLabel,
  saveLabel,
  changeLanguage,
  handleInputChange,
  handleRevert,
  handleSave,
  setSaved,
  children
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [assetSelectorOpen, setAssetSelectorOpen] = useState(false);
  const [assetSelectorParam, setAssetSelectorParam] = useState("");
  const [isThemeSelectorOpen, setIsThemeSelectorOpen] = useState(false);

  // TODO: If the form controls in the checkAndProcessCheckboxes() function
  // ever get enabled, restore this useEffect.
  //
  // useEffect(() => {
  //     checkAndProcessCheckboxes(sidebarData);
  // }, [sidebarData])

  const handleInputChangeLocal = (prop, value) => {
    /*
    The Quill RTE sometimes converts the empty string to the following 
    HTML characters: <p><br></p>. If that's the input change being 
    processed, ignore it. We do not, however, want to ignore a change 
    from a non-empty string to the Quill empty string (<p><br></p>).
    but we do want to convert the Quill empty string to an actual empty 
    string.
    */
    if (value === "<p><br></p>") {
      if (sidebarData[prop] === "") {
        return;
      }
      value = "";
    }

    if (sidebarData[prop] !== value) {
      handleInputChange(prop, value, formElements[prop].isLocalized);
      setIsDirty(true);
    }

    /*
    TODO: These form elements aren't being displaying right now.
    Leaving this logic in place, but not sure it's going to work with
    the changes made to support revert in a way that is consistent
    with how revert is handled elsewhere in the app. 
    */
    // if (prop == "limitNumberOfQuestions") {
    //   let textField = document.getElementsByClassName("numberOfQuestions")[0];
    //   if (value === true && textField) {
    //     textField.classList.add("show");
    //   } else if (textField) {
    //     textField.classList.remove("show");
    //   }
    // }

    // if (prop == "randomizeQuestions") {
    //   let textField = document.getElementsByClassName(
    //     "limitNumberOfQuestions"
    //   )[0];
    //   let textField2 = document.getElementsByClassName("numberOfQuestions")[0];
    //   if (value === true && textField) {
    //     textField.classList.add("show");
    //     let checkbox = textField.querySelector("input");
    //     if (checkbox.checked) {
    //       textField2.classList.add("show");
    //     }
    //   } else if (textField) {
    //     textField.classList.remove("show");
    //     textField2.classList.remove("show");
    //   }
    // }
  };

  const debounceInputChange = _debounce((property, value) => {
    handleInputChangeLocal(property, value);
  }, 500);

  const handleLanguageChange = event => {
    changeLanguage(event.target.value);
  };

  const handleRevertLocal = () => {
    setIsDirty(false);
    handleRevert();
  };

  const handleRevertActionFromModal = () => {
    handleRevertLocal();
    goBackAction();
  };

  const openDisplayRevertCancel = () => {
    setConfirmModal(true);
  };

  const handleDisplayRevertCancel = () => {
    setConfirmModal(false);
  };

  const handleSaveLocal = () => {
    setIsDirty(false);
    handleSave();

    // WHAT???
    // If we comment out the following code, the initial form
    // settings aren't temporarily displayed in the form.
    // if (typeof this.props.setSaved !== "undefined") {
    if (setSaved) {
      setSaved(true);
    }
  };

  const closeAssetSelector = () => {
    setAssetSelectorOpen(false);
  };

  /**
   * The user has selected an asset to assign to a story, project or
   * main menu scene. Add the asset data to the local state form data
   * to trigger display of the asset in the editor. Then trigger
   * addition of the asset to the relevant document in the store.
   *
   * @param {Array} assetID - Array of selected assetIDs.
   * @param {Array} asset - Array of asset documents that contain all
   *                        data from the Library.
   */
  const processAssetFromSelector = (assetID, asset) => {
    closeAssetSelector();

    const formData = _cloneDeep(sidebarData);

    formData.asset = {
      assetID: assetID[0],
      isCloudinary: true,
      cloudName: asset[0].cloudName,
      cloudPublicId: asset[0].cloudPublicId,
      config: asset[0].config,
      height: asset[0].height,
      width: asset[0].width,
      type: asset[0].type
    };

    // Long term, we need to get the assetParam from the form.
    const assetParam = section === "mainmenu" ? assetSelectorParam : "asset";
    handleInputChange(assetParam, formData.asset, false);
    setIsDirty(true);
  };

  const handleAssetReplace = props => {
    const { param } = props;
    setAssetSelectorOpen(true);
    setAssetSelectorParam(param);
  };

  /**
   * The user has clicked the trashcan icon on the asset. Remove the
   * asset data from the local state form data to trigger removal of the
   * asset from the editor. Then trigger removal of the asset from the
   * story in the store.
   * @param {Object} data - No idea what this object represents. Looks
   *                        like an amalgamation of all form data.
   */
  const handleAssetDelete = data => {
    const formData = _cloneDeep(sidebarData);

    formData.asset = {
      assetID: null
    };

    // Long term, we need to get the assetParam from the form.
    const assetParam = section === "mainmenu" ? data.use : "asset";

    // handleForeignDependencies(formElements, handleInputChange);

    handleInputChange(assetParam, formData.asset, false);
    setIsDirty(true);
  };

  /* THEME MANAGEMENT  */

  // TODO: Figure out why how the theme selector is
  // ever rendered in an open state since this function
  // doesn't get called. Does that mean theme selection
  // doesn't actually happen here?
  const openThemeSelector = event => {
    event.preventDefault();
    setIsThemeSelectorOpen(true);
  };

  const closeThemeSelector = () => {
    setIsThemeSelectorOpen(false);
  };

  const handleThemeSelect = themeId => {
    closeThemeSelector();

    if (sidebarData.selectedTheme._id !== themeId) {
      handleInputChange("themeId", themeId, false);
      setIsDirty(true);
    }
  };

  // Injecting asset editor handlers
  if (formElements) {
    Object.keys(formElements).forEach(key => {
      // Support for cropping a story image has not yet been
      // implemented. Currently, user can only replace existing
      // image.
      if (formElements[key].type === "assetEditor") {
        formElements[key].handleEditClick = handleAssetReplace;
        formElements[key].handleDeleteClick = handleAssetDelete;
        formElements[key].handleAddClick = handleAssetReplace;
      } else if (formElements[key].type === "chooser") {
        formElements[key].handleActionClick = openThemeSelector;
      }
    });
  }

  // TODO: If the form controls in the checkAndProcessCheckboxes() function
  // ever get enabled, restore this useEffect.
  //
  // setTimeout(() => checkAndProcessCheckboxes(sidebarData), 500);

  return (
    <Sidebar
      title={title}
      className={className}
      goBackAction={!isDirty && goBackAction}
      showButtons={
        isDirty &&
        (section === "settings" || section === "design" || showButtons)
      }
      handleSave={handleSaveLocal}
      hideRevertButton={hideRevertButton}
      revertLabel={revertLabel}
      handleRevert={handleRevertLocal}
      saveLabel={saveLabel}
    >
      {/* We should be able to just check if a changeLanguage() function
        is defined rather than looking at the section. If the changeLanguage()
        function gets passed when it's not necessasry, change that in the
        consumer.
         */}
      {/* {section === "settings" || section === "mainmenu" ? ( */}
      {changeLanguage && (
        <div className="language-selector-container">
          <Select
            className="language-select"
            value={langID || "en"} // TODO: Determine if lang is ever an empty string.
            onChange={handleLanguageChange}
          >
            {languages &&
              _map(languages, item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </div>
      )}

      {/* The formElements object contains a property for each form
        element. These values are defined in the database. */}
      {formElements && Object.keys(formElements).length > 0 && (
        <List
          items={formElements}
          data={sidebarData}
          handleInputChange={(property, value) =>
            debounceInputChange(property, value)
          }
          slugBase={slugBase}
          styleName={
            section === "settings" ||
            section === "design" ||
            section === "mainmenu" ||
            section === "publish" ||
            section === "questions"
              ? "plain"
              : "boxed"
          }
        />
      )}

      <Dialog
        content="Please confirm that you would like to revert your changes"
        onConfirm={handleRevertLocal}
        onCancel={handleDisplayRevertCancel}
        open={confirmModal}
        title="Confirm Revert Action"
      />

      {(section === "settings" || section === "mainmenu") &&
        assetSelectorOpen && (
          <AssetSelector
            open
            minWidthCaption={parseInt(styles.minWidthCaption, 10)}
            minCardMargin={parseInt(styles.minCardMargin, 10)}
            captionHeight={parseInt(styles.captionHeight, 10)}
            isModal
            standardBarButtonAction={processAssetFromSelector}
            title="Select Asset"
            hideStandardButtonIfNonSelected
            allowsMultiple={false}
            standardBarButtonLabel="SELECT"
            sidebarMode={sidebarMode}
            isAssetSelectorMode
            closeModalAction={closeAssetSelector}
          />
        )}

      {section === "design" && isThemeSelectorOpen && (
        <ThemeSelector
          isOpen
          handleThemeSelect={handleThemeSelect}
          handleCloseClick={closeThemeSelector}
          selectedThemeId={sidebarData.selectedTheme._id}
          isModal
        />
      )}

      {children}
    </Sidebar>
  );
};
