import React, { Component } from "react";

import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";

export class Dialog extends Component {
  render() {
    const {
      open = false,
      cancelLabel = "Cancel",
      confirmLabel = "Confirm",
      completeLabel = "OK",
      processingLabel = "Saving...",
      disableCancelButton = false,
      disableConfirmButton = false,
      onCancel,
      onConfirm,
      isProcessing,
      processComplete,
      title,
      content,
      className
    } = this.props;

    let confirmButtonProps = {
      onClick: onConfirm,
      disabled: isProcessing || disableConfirmButton ? true : false
    };

    const cancelButtonProps = {
      onClick: onCancel,
      disabled: disableCancelButton
    };

    const ButtonConfirmComponent = () => {
      const label = isProcessing ? processingLabel : confirmLabel;
      return (
        <Button
          key="confirm-button"
          variant="contained"
          color="primary"
          {...confirmButtonProps}
        >
          {label}
        </Button>
      );
    };

    const ButtonCancelComponent = () => {
      return (
        <Button key="cancel-button" {...cancelButtonProps}>
          {cancelLabel}
        </Button>
      );
    };

    let actions = [<ButtonCancelComponent />, <ButtonConfirmComponent />];

    if (processComplete) {
      actions = [<Button onClick={onCancel}>{completeLabel}</Button>];
    }

    return (
      <MUIDialog
        open={open}
        onClose={onCancel}
        className={className || "app-dialog"}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {content || this.props.children || ""}
          </Typography>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </MUIDialog>
    );
  }
}
