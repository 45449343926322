import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import _debounce from "lodash/debounce";
import _map from "lodash/map";

import { IconButton, Button } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import Logger from "utils/logger";
import { PreviewSelector, SlideshowScene } from "components";
import ContentContainer from "modules/ContentContainer";
import ThemeSelector from "modules/ThemeSelector";
import { slideshowEditorMapStateToProps } from "mapToProps/sceneEditor";
import BackgroundEditModal from "./BackgroundEditModal";
import StyleEditModal2 from "./StyleEditModal2";
import * as sceneActions from "actions/sceneEditor";
import "./styles.scss";
import styles from "./styles.scss";

const SlideshowEditor = ({
  sceneType,
  storyData,
  styleForms,
  elementID,
  userPreferences,
  accountProjectDefaults,
  storyClasses,
  activeEditingScene,
  isLoading = false,
  dispatchMount,
  onThemeOptionChange,
  openAssetSelectorFunc,
  openLayoutSelectorFunc,
  setActiveElementFunc,
  handleSceneStyleChange,
  handleStyleSettingsCancelClick,
  handleSceneStyleSettingsSaveClick,
  handleContentAreaStyleChange,
  handleBackgroundImageEditClick,
  updateBackground,
  userPreferencesSelector,
  sidebarMode,
  curSlideNo,
  totalSlideNo,
  goPrevURL = "",
  goNextURL = "",
  goBackURL,
  setActiveEditingElement
}) => {
  const history = useHistory();

  const [isThemeSelectorOpen, setIsThemeSelectorOpen] = useState(false);
  const [isBackgroundModalOpen, setIsBackgroundModalOpen] = useState(false);
  const [isSceneStyleModalOpen, setIsSceneStyleModalOpen] = useState(false);
  const [isContentAreaStyleModalOpen, setIsContentAreaStyleModalOpen] =
    useState(false);
  const [focusedContentAreaID, setFocusedContentAreaID] = useState(null);

  useEffect(() => {
    dispatchMount();
  }, []);

  const handleSequentialNavClick = path => {
    history.push(path);
  };

  /* THEME MANAGEMENT  */
  const openThemeSelector = e => {
    e.preventDefault();
    setIsThemeSelectorOpen(true);
  };

  const closeThemeSelector = () => {
    setIsThemeSelectorOpen(false);
  };

  const handleThemeSelect = themeId => {
    closeThemeSelector();
    onThemeOptionChange(themeId);
  };

  /* BACKGROUND MANAGEMENT */
  const openBackgroundModal = () => {
    setActiveElementFunc("background");
    setIsBackgroundModalOpen(true);
  };

  const closeBackgroundModal = () => {
    setIsBackgroundModalOpen(false);
  };

  const openAssetSelector = (value, multiple) => {
    closeBackgroundModal();
    openAssetSelectorFunc(value, multiple);
  };

  const openLayoutSelector = () => {
    openLayoutSelectorFunc();
  };

  const openSceneStyleModal = () => {
    setActiveElementFunc("style");
    setIsSceneStyleModalOpen(true);
  };

  const closeSceneStyleModal = () => {
    setIsSceneStyleModalOpen(false);
    handleStyleSettingsCancelClick();
  };

  const handleSceneStyleModalSaveClick = () => {
    setIsSceneStyleModalOpen(false);
    handleSceneStyleSettingsSaveClick();
  };

  const openContentAreaStyleModal = areaID => {
    setIsContentAreaStyleModalOpen(true);
    setFocusedContentAreaID(areaID);
  };

  const closeContentAreaStyleModal = () => {
    setIsContentAreaStyleModalOpen(false);
    setFocusedContentAreaID(null);
    handleStyleSettingsCancelClick();
  };

  const handleContentAreaStyleModalSaveClick = () => {
    setIsContentAreaStyleModalOpen(false);
    setFocusedContentAreaID(null);
    handleSceneStyleSettingsSaveClick();
  };

  const processBackgroundSave = data => {
    const buildData = { ...data.background };

    // Define color property on the background object
    // using the backgroundColor property.
    buildData.color =
      data.backgroundColor && data.backgroundColor.hex
        ? data.backgroundColor.hex
        : data.backgroundColor;

    /*
     Some properties that are injected into the background
     component in the destination need to be segregated out
     into the element styles object when passed to the API.
     Other props are class props that will be manually 
     written out to different contexts, and they need to be
     moved to the classes object.
     */
    if (typeof data.background.styles === "undefined") {
      buildData.styles = {};
    }
    if (typeof data.background.classes === "undefined") {
      buildData.classes = {};
    }

    const stylePropsToRemap = ["objectFit", "objectPosition", "repeat"];
    const classPropsToRemap = [
      "themeBackground",
      "backgroundFilter",
      "backgroundEffect",
      "backgroundAnimation",
      "backgroundAnimationSpeed"
    ];

    const newStyles = {};
    stylePropsToRemap.forEach(prop => {
      if (data.background && data.background[prop] !== undefined) {
        newStyles[prop] = data.background[prop];
      }
      delete buildData[prop];
    });
    buildData.styles = { ...newStyles };

    const newClasses = {};
    classPropsToRemap.forEach(prop => {
      if (data.background && data.background[prop] !== undefined) {
        newClasses[prop] = data.background[prop];
      }
      delete buildData[prop];
    });
    buildData.classes = { ...newClasses };

    /*
    Not sure what purpose the following objects serve
    on the background object in the CMS, but they don't
    belong on the background element root in the DB.
    */
    delete buildData.formType;
    delete buildData.config;

    closeBackgroundModal();
    updateBackground(buildData);
  };

  const topBar = (
    <div className="slideshow-editor-topbar">
      <Button
        className="header-select"
        style={{ textTransform: "none" }}
        onClick={openThemeSelector}
      >
        Change Preview Theme
      </Button>

      <PreviewSelector
        value={userPreferences.preview}
        userPreferencesSelector={userPreferencesSelector}
      />

      {sceneType !== "cover" ? (
        <div className="scene-navigation-component">
          {goPrevURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="back-arrow"
              onClick={() => {
                handleSequentialNavClick(goPrevURL);
              }}
            >
              <ChevronLeft viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : null}
          <div className="slide-numbers-container">
            {curSlideNo}
            &nbsp; of &nbsp;
            {totalSlideNo}
          </div>
          {goNextURL ? (
            <IconButton
              style={{ paddingTop: 23, float: "left", marginLeft: 12 }}
              disableTouchRipple
              className="next-arrow"
              onClick={() => {
                handleSequentialNavClick(goNextURL);
              }}
            >
              <ChevronRight viewBox="4 0 24 24" color="rgba(0, 0, 0, .87)" />
            </IconButton>
          ) : (
            <div className="empty-arrow" />
          )}
        </div>
      ) : null}
    </div>
  );

  const headerActions = (
    <div className="buttons-container">
      <Button
        style={{ textTransform: "none", textAlign: "left" }}
        onClick={openLayoutSelector}
      >
        Layout
      </Button>
      <Button
        style={{ textTransform: "none", textAlign: "left" }}
        onClick={openBackgroundModal}
      >
        Background
      </Button>
      <Button
        style={{ textTransform: "none", textAlign: "left" }}
        onClick={openSceneStyleModal}
      >
        Style
      </Button>
    </div>
  );

  const themeStyleSheet = userPreferences.theme
    ? `dest-theme-${userPreferences.theme}.css`
    : `dest-theme-${accountProjectDefaults.theme.themeId}.css`;

  return (
    <div className="slideshow-editor">
      <Helmet>
        <title>
          {sceneType === "cover" ? "Cover Editor" : "Slideshow Editor"}
        </title>

        <link
          rel="stylesheet"
          href={"https://storycrafter.co/sc-structure-sc4.css"}
        />

        <link
          rel="stylesheet"
          href={`https://storycrafter.co/${themeStyleSheet}`}
        />
      </Helmet>

      <ContentContainer
        backgroundColor={styles.contentContainerBack}
        isLoading={isLoading}
        sidebarMode={sidebarMode}
        closeAction={goBackURL}
        headerTheme="light"
        title={sceneType === "cover" ? "Cover Editor" : "Slide Editor"}
        headerMode="extended"
        headerActions={headerActions}
        topBar={topBar}
      >
        <div
          className={`slideshow-editor-container scene-container content preview-${
            userPreferences.preview || "dm"
          } ${storyClasses ? storyClasses : ""}`}
        >
          <SlideshowScene
            elements={activeEditingScene ? activeEditingScene.elements : {}}
            backgroundColor={
              activeEditingScene &&
              activeEditingScene.elements &&
              activeEditingScene.elements.background
                ? activeEditingScene.elements.background.color
                : undefined
            }
            classNames={activeEditingScene.classNames}
            styles={activeEditingScene.styles}
            layout={activeEditingScene ? activeEditingScene.layout : undefined}
            layoutElements={
              activeEditingScene ? activeEditingScene.layoutElements : {}
            }
            template={activeEditingScene ? activeEditingScene.template : []}
            isEditMode
            playAsModal={false}
            onContentAreaClick={openContentAreaStyleModal}
            triggerEditFunc={setActiveEditingElement}
            activeEditingElement={elementID}
            backClickHandler={() => {
              setActiveEditingElement("");
            }}
            assetSelectorFunc={value => openAssetSelector(value, false)}
            storyProperties={storyData}
          />
        </div>
      </ContentContainer>

      {isBackgroundModalOpen && (
        <BackgroundEditModal
          open
          backgroundData={
            activeEditingScene &&
            activeEditingScene.elements &&
            activeEditingScene.elements.background &&
            Object.keys(activeEditingScene.elements.background).length > 0
              ? {
                  ...activeEditingScene.elements.background,
                  ...activeEditingScene.elements.background.styles,
                  ...activeEditingScene.elements.background.classes
                }
              : undefined
          }
          initialColor={
            activeEditingScene &&
            activeEditingScene.elements &&
            activeEditingScene.elements.background &&
            Object.keys(activeEditingScene.elements.background).length > 0
              ? activeEditingScene.elements.background.color
                ? activeEditingScene.elements.background.color
                : undefined
              : undefined
          }
          handleSave={processBackgroundSave}
          closeAction={closeBackgroundModal}
          openAssetSelectorFunc={() => openAssetSelector("background", false)}
          handleImageEditClick={props => handleBackgroundImageEditClick(props)}
        />
      )}

      {isSceneStyleModalOpen && (
        <StyleEditModal2
          title="Slide Styles"
          tabs={styleForms.scene.tabs}
          forms={styleForms.scene.forms}
          formData={
            activeEditingScene.styles && activeEditingScene.styles.scene
              ? activeEditingScene.styles.scene
              : null
          }
          onInputChange={(name, value) => handleSceneStyleChange(name, value)}
          onCancel={closeSceneStyleModal}
          onSave={handleSceneStyleModalSaveClick}
        />
      )}

      {isContentAreaStyleModalOpen && (
        <StyleEditModal2
          title="Content Area Styles"
          tabs={styleForms.contentArea.tabs}
          forms={styleForms.contentArea.forms}
          formData={
            activeEditingScene.styles &&
            activeEditingScene.styles.contentAreas &&
            activeEditingScene.styles[`area${focusedContentAreaID}`]
              ? activeEditingScene.styles[`area${focusedContentAreaID}`]
              : null
          }
          onInputChange={(name, value) =>
            handleContentAreaStyleChange(focusedContentAreaID, name, value)
          }
          onCancel={closeContentAreaStyleModal}
          onSave={handleContentAreaStyleModalSaveClick}
        />
      )}

      {isThemeSelectorOpen && (
        <ThemeSelector
          isOpen
          handleThemeSelect={handleThemeSelect}
          handleCloseClick={closeThemeSelector}
          selectedThemeId={userPreferences.theme || 0}
          isModal
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return slideshowEditorMapStateToProps(state, ownProps);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { storyID, slugBase, sceneID, elementID } = ownProps;
  const { userPreferencesSelector } = stateProps;

  const handleSearchDebounced = _debounce(
    (property, value) =>
      dispatchProps.setDataFromInput.apply(this, [
        property,
        value,
        storyID,
        sceneID,
        elementID
      ]),
    500
  );

  const debounceHandleSceneStyleChange = _debounce(
    (sceneID, name, value) =>
      dispatchProps.dispatchSceneStyleChange.apply(this, [
        sceneID,
        name,
        value
      ]),
    500
  );

  const debounceHandleContentAreaStyleChange = _debounce(
    (sceneID, areaID, name, value) =>
      dispatchProps.dispatchContentAreaStyleChange.apply(this, [
        sceneID,
        areaID,
        name,
        value
      ]),
    500
  );

  return Object.assign({}, stateProps, {
    dispatchMount: () => dispatchProps.slideshowSceneEditorMount(),

    handleInputChange: (property, value) =>
      handleSearchDebounced(property, value),

    updateBackground: data => {
      dispatchProps.updateScene(
        sceneID,
        data,
        null,
        "slideshow-slide",
        null,
        "background"
      );
    },

    handleBackgroundImageEditClick: props => {
      // I have no idea why this function can be accessed here, but
      // not in the render() function above.
      Logger.debug(
        { props },
        "SlideshowEditor: handleBackgroundImageEditClick"
      );
      ownProps.openImageCropperFunc("background");
    },

    setActiveEditingElement: elementID => {
      // NOTE: seems we are getting the use NOT the ID back from the slideshow viewer
      const url = elementID
        ? `${slugBase}/${sceneID}/elements/${elementID}`
        : `${slugBase}/${sceneID}`;
      ownProps.history.push(url);
    },

    openAssetSelectorFunc: (value, multiple) =>
      ownProps.openAssetSelectorFunc(value, multiple),

    openLayoutSelectorFunc: () => ownProps.openLayoutSelectorFunc(),

    setActiveElementFunc: element => ownProps.setActiveElementFunc(element),

    // As of 11/28/2018, it doesn't appear that deleteElement()
    // is being used anywhere.
    //
    // deleteElement: (sceneID, elementID) => {
    //   if (elementID === 'background') {
    //     dispatchProps.updateScene(
    //       sceneID,
    //       { assetID: null, type: 'asset' },
    //       null,
    //       'slideshow-slide',
    //       null,
    //       'background',
    //     );
    //   } else {
    //     dispatchProps.setActiveEditingEålement(null);
    //     dispatchProps.deleteSceneAssetElement(sceneID, elementID);
    //   }
    // },

    onThemeOptionChange: value =>
      dispatchProps.dispatchUserPreferenceChange(
        userPreferencesSelector,
        "theme",
        value
      ),

    handleSceneStyleChange: (name, value) =>
      debounceHandleSceneStyleChange(sceneID, name, value),

    handleContentAreaStyleChange: (areaID, name, value) =>
      debounceHandleContentAreaStyleChange(sceneID, areaID, name, value),

    handleSceneStyleSettingsSaveClick: () =>
      dispatchProps.dispatchSceneStyleSettingsSaveClick(sceneID),

    handleStyleSettingsCancelClick: () => dispatchProps.revertScene()
  });
};

export default withRouter(
  connect(mapStateToProps, sceneActions, mergeProps)(SlideshowEditor)
);
